.pdes {
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  color: #1a1c6a;
}
.center1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
img.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
img.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
