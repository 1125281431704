.process h4 {
  color: #282b8f;
}
.process ul li button .active {
  color: black !important;
  background-color: rgb(191, 213, 51) !important;
}
.process ul li button {
  color: grey;
}
.process a {
  color: inherit;
}
.process li {
  font-size: 25px;
  padding: 10px;
}
.process ul {
  align-content: center;
}
.process .acc {
  width: 80%;
}
.fs {
  font-size: 20px;
  text-align: justify;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1000px) and (min-width: 300px) {
  .process li {
    font-size: 13px;
    padding: 0px;
    text-decoration: bold;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
