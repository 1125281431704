.feature h5 {
  text-align: center;
  color: #282b8f;
}
.feature p {
  text-align: center;
}
.feature img {
  display: grid;
  align-items: center;
  width: 15%;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
