.animatedT {
    animation: fade 0.2s ease-in;
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}